<template>
  <BaseViewWrapper>
    ...
  </BaseViewWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    

    return {}
  },
})
</script>
